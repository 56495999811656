import React from 'react'
import general from '../styles/general.module.css'

const serializers = {
    types: {
        authorReference: ({node}) => <span>{node.author.name}</span>,
        mainImage: props => {
            return (
                <div className={general.mainImageContainer} >
                    <img src={props.node.asset.url} className={general.mainImage} />
                </div>
            )
        },
        block: props => { 
            if (props.node.children[0].marks.includes('box')) {
                return <p className={general.boxParagragh}>{props.children}</p>
            }

            return <p className={general.paragraph}>{props.children}</p>
        },
        iconReference: props => {
            return (
                <div>
                    <img src={props.node.icon._rawIconImage.asset.url} className={general.sectionIcon} />
                </div>
            )
        },
        fileReference: props => {
            const fileDocument = props.node.fileDocument;

            return (
                <a href={fileDocument._rawFile.asset.url} target="_blank">{fileDocument.title}</a>
            )
        }
    },
    marks: {
        box: props => {
            return (
                <span className={general.boxMark}>{props.children}</span>
            )
        },
        link: props => {
            return (
                <a href={props.mark.href} target="_blank">{props.children}</a>
            )
        }
    },
    list(props) {
        if (props.type === 'bullet') {
            if (props.children[0].props.node.children[0].marks.includes("box")) {
                return (
                    <ul className={general.boxList}>{props.children}</ul>
                )
            } else {
                return <ul>{props.children}</ul>
            }
        } else {
            if (props.children[0].props.node.children[0].marks.includes("box")) {
                return (
                    <ol className={general.boxList}>{props.children}</ol>
                )
            } else {
                return <ol>{props.children}</ol>
            }
        }
    }
}

export default serializers
