import React from 'react'
import ReactTooltip from 'react-tooltip'
import { IoIosArrowDown } from "react-icons/io"
import Collapsible from 'react-collapsible'
import PortableText from '@sanity/block-content-to-react'
import serializers from '../lib/serializers'

import general from '../styles/general.module.css'

class LinkLists extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            styles: this.props.styles
        }
    }

    getLinkLists = (contents) => {
        const sideContent = [];
    
        contents.map(content => {
            switch (content.linkListShowAs) {
                case ("iconList"):
                    sideContent.push(this.getIconList(content));
                    break;
    
                case ("regular"):
                    sideContent.push(this.getRegularList(content));
                    break;
    
                case ("fileList"):
                    sideContent.push(this.getFileList(content));
                    break;

                case ('contributorList'):
                    sideContent.push(this.getContributorList(content));
                    break;
            }
        })
    
        return sideContent;
    }
    
    getIconList = (content) => {
        const iconList = content.linkList;
        const withToolTip = content.showIconsWithToolTip;
        
        const iconComponent = iconList.map(icon => {
            const ref = icon.internalLink.reference;
            const url = ref.iconImage.asset.url || null;
            const tooltip = ref._rawTooltipText ? ref._rawTooltipText[0].children[0].text : null;
            
            return (
                <div key={ref.id} className={general.iconWrapper}>
                    <img src={url} data-tip data-for={ref.id} />
                    <p className={this.state.styles.iconTitle}>{icon.title}</p>
                    {withToolTip && <ReactTooltip id={ref.id} className={general.tooltip} multiline={true} effect='solid' >
                        <h4>{icon.title}</h4>
                        <p>{tooltip}</p>    
                    </ReactTooltip>}
                </div>
            )
        })
    
        let listComponent = '';
    
        if (content.showAsAccordion) {
            const triggerTitle =
                <div>
                    <p>{content.title}</p>
                    <span><IoIosArrowDown /></span>
                </div>
    
            listComponent = (
                <Collapsible trigger={triggerTitle} key={content.id}
                    className={this.state.styles.sectionBox}
                    triggerClassName={this.state.styles.sideContentTitle}
                    triggerOpenedClassName={this.state.styles.sideContentTitle}
                    openedClassName={this.state.styles.sectionOpened}
                    transitionTime={100}
                    transitionCloseTime={100}
                >
                    <div className={this.state.styles.sideSectionContent}>
                        {iconComponent}
                    </div>
                </Collapsible>
            )
        } else {
            listComponent = (
                <div key={content.id} className={this.state.styles.sideContentContainer}>
                    <h2 className="large">{content.title}</h2>
                    <div className={this.state.styles.sideItemsContainer}>
                        {iconComponent}
                    </div>
                </div>
            )
        }
    
        return listComponent;
    }
    
    getRegularList = (content) => {
        const linkList = content._rawLinkList;
    
        const linkComponent = linkList.map(link => {
            const url = link.internalLink ? link.internalLink.reference._rawFile.asset.url : link.url;
    
            return (
                <div key={link._key} className={this.state.styles.sideLinkWrapper}>
                    <a href={url} key={link._key} target={link.openLinkInNewWindow ? "_blank" : "_self"}><p>{link.title}</p></a>
                </div>
            )
        })
    
        let listComponent = '';
    
        if (content.showAsAccordion) {
            const triggerTitle =
                <div>
                    <p>{content.title}</p>
                </div>
    
            listComponent = (
                <Collapsible trigger={triggerTitle} key={content.id}
                    className={this.state.styles.sectionBox}
                    triggerClassName={this.state.styles.sideContentTitle}
                    triggerOpenedClassName={this.state.styles.sideContentTitle}
                    openedClassName={this.state.styles.sectionOpened}
                    transitionTime={100}
                    transitionCloseTime={100}
                    open={true}
                >
                    <div className={this.state.styles.sideSectionContent}>
                        {linkComponent}
                    </div>
                </Collapsible>
            )
        } else {
            listComponent = (
                <div key={content.id} className={this.state.styles.sideContentContainer}>
                    <h2 className="large">{content.title}</h2>
                    <div className={this.state.styles.sideItemsContainer}>
                        {linkComponent}
                    </div>
                </div>
            )
        }
    
        return listComponent;
    }
    
    getFileList = (content) => {
        const linkList = content._rawLinkList;
    
        const linkComponent = linkList.map(link => {
            const url = link.internalLink.reference._rawFile.asset.url;
    
            return (
                <div key={link._key} className={this.state.styles.sideLinkWrapper}>
                    <a href={url} key={link._key} target={link.openLinkInNewWindow ? "_blank" : "_self"}><p>{link.title}</p></a>
                </div>
            )
        })
    
        let listComponent = '';
    
        if (content.showAsAccordion) {
            const triggerTitle =
                <div>
                    <p>{content.title}</p>
                </div>
    
            listComponent = (
                <Collapsible trigger={triggerTitle} key={content.id}
                    className={this.state.styles.sectionBox}
                    triggerClassName={this.state.styles.sideContentTitle}
                    triggerOpenedClassName={this.state.styles.sideContentTitle}
                    openedClassName={this.state.styles.sectionOpened}
                    transitionTime={100}
                    transitionCloseTime={100}
                    open={true}
                >
                    <div className={this.state.styles.sideSectionContent}>
                        {linkComponent}
                    </div>
                </Collapsible>
            )
        } else {
            listComponent = (
                <div key={content.id} className={this.state.styles.sideContentContainer}>
                    <h2 className="large">{content.title}</h2>
                    <div className={this.state.styles.sideItemsContainer}>
                        {linkComponent}
                    </div>
                </div>
            )
        }
    
        return listComponent;
    }

    getContributorList = (content) => {
        const contList = content._rawLinkList;

        const contComponent = contList.map(cont => {
            return (
                <div key={cont._key} className={this.state.styles.contentSection}>
                    <div className={this.state.styles.sideImageContainer}>
                        <a href={cont.url || null} target={cont.openLinkInNewWindow ? "_blank" : "_self"}>
                            <img src={cont.mainImage.asset ? cont.mainImage.asset.url : null} className={this.state.styles.sideImage} alt={cont.mainImage.alt || null}></img>
                        </a>
                        <span>{cont.mainImage.caption || null}</span>
                    </div>
                    <div className={this.state.styles.sideDescription}>
                        <p>{cont.title}</p>
                        <PortableText
                            blocks={cont.description}
                            serializers={serializers}
                            className={this.state.styles.portableText}
                        />
                    </div>
                </div>
            )
        })

        let listComponent = '';

        if (content.showAsAccordion) {
            const triggerTitle =
                <div>
                    <h2 className="large">{content.title}</h2>
                    <span><IoIosArrowDown /></span>
                </div>

            listComponent = (
                <Collapsible trigger={triggerTitle} key={content.id}
                    className={this.state.styles.sectionBox}
                    triggerClassName={this.state.styles.sectionTrigger}
                    triggerOpenedClassName={this.state.styles.sectionTriggerOpened}
                    openedClassName={this.state.styles.sectionOpened}
                    transitionTime={100}
                    transitionCloseTime={100}
                >
                    <div className={this.state.styles.sideSectionContent}>
                        {contComponent}
                    </div>
                </Collapsible>
            )
        } else {
            listComponent = (
                <div key={content.id} className={this.state.styles.sideDescription}>
                    <h2 className="large">{content.title}</h2>
                    <div className={this.state.styles.sideItemsContainer}>
                        {contComponent}
                    </div>
                </div>
            )
        }

        return listComponent;
    }
    
    render() {
        const sideContent = this.getLinkLists(this.props.content);        
        
        return (
            <div>
                {sideContent}
            </div>
        )
    }
}


export default LinkLists